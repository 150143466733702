<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-12 20:36:16
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 11:00:16
 * @FilePath: /siana_admin/src/views/system/platform/index.vue
-->
<template>
  <el-card>
    <div slot="header">
      <h2>平台设置</h2>
    </div>
    <el-form ref="ruleForm"
      :model="form"
      :rules="rules"
      label-width="130px"
      :label-position="phone?'top':'right'"
      class="demo-ruleForm">
      <el-form-item
        label="注册模式"
        prop="registrationMethod">
        <el-select
          v-model="form.registrationMethod"
          :disabled="disabled"
          style="width:100%;max-width: 600px;"
          placeholder="请选择注册模式">
          <el-option
            label="账号密码"
            :value="1" />
          <el-option
            label="邮箱注册"
            :value="2" />
          <el-option
            label="公众号"
            :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="GPT请求地址"
        prop="gptUrl">
        <el-input
          v-model="form.gptUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="默认注册次数"
        prop="defaultTimes">
        <el-input
          v-model="form.defaultTimes"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="图片上传路径"
        prop="imgUploadUrl">
        <el-input
          v-model="form.imgUploadUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="图片前缀地址"
        prop="imgReturnUrl">
        <el-input
          v-model="form.imgReturnUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="后台接口地址"
        prop="apiUrl">
        <el-input
          v-model="form.apiUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="客户端页面地址"
        prop="clientUrl">
        <el-input
          v-model="form.clientUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        v-if="!disabled">
        <el-button
          type="primary"
          @click="handleSubmit()">保存</el-button>
        <el-button
          @click="handleCancel()">取消</el-button>
      </el-form-item>
      <el-form-item v-else>
        <el-button
          type="primary"
          @click="disabled = !disabled">去编辑</el-button>

      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      phone: false,
      form: {
        registrationMethod: 0, // 注册模式  1 账号密码 2 邮箱注册 3 公众号
        gptUrl: '', // gpt请求地址
        defaultTimes: '', // 默认注册次数
        imgUploadUrl: '', // 图片上传路径
        imgReturnUrl: '', // 图片返回前缀地址
        apiUrl: '', // 后台接口地址
        clientUrl: '' // 客户端页面地址
      },
      disabled: true,
      rules: {
        registrationMethod: [{ required: false, message: '请选择注册模式', trigger: 'blur' }],
        gptUrl: [{ required: false, message: '请输入', trigger: 'blur' }],
        defaultTimes: [{ required: false, message: '请输入', trigger: 'blur' }],
        imgUploadUrl: [{ required: false, message: '请输入', trigger: 'blur' }],
        imgReturnUrl: [{ required: false, message: '请输入', trigger: 'blur' }],
        apiUrl: [{ required: false, message: '请输入', trigger: 'blur' }],
        clientUrl: [{ required: false, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    this.getData()
  },
  methods: {
    getData() {
      this.$https('SYSTEMSETTING', {}).then(res => {
        this.form = {
          id: res.data.id,
          registrationMethod: res.data.registrationMethod, // 注册模式  1 账号密码 2 邮箱注册 3 公众号
          gptUrl: res.data.gptUrl, // gpt请求地址
          defaultTimes: res.data.defaultTimes, // 默认注册次数
          imgUploadUrl: res.data.imgUploadUrl, // 图片上传路径
          imgReturnUrl: res.data.imgReturnUrl, // 图片返回前缀地址
          apiUrl: res.data.apiUrl, // 后台接口地址
          clientUrl: res.data.clientUrl
        }
      })
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$https('SYSTEMEDIT', this.form).then(res => {
            if (res.status == 200) {
              this.$message.success('编辑成功！')
              this.disabled = true
            }
          })
        }
      })
    },
    handleCancel() {
      this.form = {
        id: '',
        registrationMethod: 0, // 注册模式  1 账号密码 2 邮箱注册 3 公众号
        gptUrl: '', // gpt请求地址
        defaultTimes: '', // 默认注册次数
        imgUploadUrl: '', // 图片上传路径
        imgReturnUrl: '', // 图片返回前缀地址
        apiUrl: '', // 后台接口地址
        clientUrl: ''
      }
      this.getData()
      this.disabled = true
    }
  }
}
</script>

<style>
</style>
